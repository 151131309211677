import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderBuero from "../components/header_buero"

const PartnerGeschichtePage = ({ data }) => {
  const history = data.strapiHistory

  return (
    <Layout>
      <SEO title={history.title} />
      <HeaderBuero />

      <div className="container-two">
        <div className="container-left">
          <Img
            fluid={history.picture.localFile.childImageSharp.fluid}
          />
        </div>
        <div className="container-right">
          <h4>{history.bueroname}</h4>
          <Reactmarkdown>{history.content}</Reactmarkdown>
        </div>
      </div>
      
    </Layout>
  )
}

export default PartnerGeschichtePage

export const query = graphql`
  query PartnerGeschichteQuery($slug: String!) {
    strapiHistory(slug: { eq: $slug }) {
      title
      content
      bueroname
      picture {
        localFile {
          childImageSharp {
            fluid(maxHeight: 470) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
